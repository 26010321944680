import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes local-class='ak-tabs-container' data-test-ak-tabs-container>\n  <AkStack\n    @tag='ul'\n    @direction='row'\n    role='tablist'\n    data-test-ak-tab-list-wrapper\n  >\n    {{yield (hash tabItem=(component 'ak-tabs/item'))}}\n  </AkStack>\n</div>", {"contents":"<div ...attributes local-class='ak-tabs-container' data-test-ak-tabs-container>\n  <AkStack\n    @tag='ul'\n    @direction='row'\n    role='tablist'\n    data-test-ak-tab-list-wrapper\n  >\n    {{yield (hash tabItem=(component 'ak-tabs/item'))}}\n  </AkStack>\n</div>","moduleName":"irene/components/ak-tabs/index.hbs","parseOptions":{"srcName":"irene/components/ak-tabs/index.hbs"}});
import Component from '@glimmer/component';
import AkTabsItemComponent from './item';

export interface AkTabsSignature {
  Element: HTMLElement;
  Args: null;
  Blocks: { default: [{ tabItem: typeof AkTabsItemComponent }] };
}

export default class AkTabsComponent extends Component<AkTabsSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkTabs: typeof AkTabsComponent;
  }
}
