import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='box-container margin-top'>\n  <div class='columns'>\n    <AccountSettings::DeveloperSettings::PersonalTokenList />\n  </div>\n</div>", {"contents":"<div class='box-container margin-top'>\n  <div class='columns'>\n    <AccountSettings::DeveloperSettings::PersonalTokenList />\n  </div>\n</div>","moduleName":"irene/components/account-settings/developer-settings/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/developer-settings/index.hbs"}});
import Component from '@glimmer/component';

export default class AccountSettingsDeveloperSettingsComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountSettings::DeveloperSettings': typeof AccountSettingsDeveloperSettingsComponent;
  }
}
