import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<h2 class='black-text bold padding-t-3q padding-l-h'>\n  {{t 'marketplace'}}\n</h2>\n\n<div class='padding-t-2 padding-l-1'>\n  <h3 class='black-text bold'>\n    {{t 'plugins'}}\n  </h3>\n\n  <div class='padding-b-h'>\n    {{t 'marketplacePluginDesc'}}\n  </div>\n</div>\n\n<div class='padding-b-2'>\n  <Marketplace::PluginList />\n</div>\n\n<div class='padding-l-1'>\n  <h3 class='black-text bold'>\n    {{t 'integration'}}\n  </h3>\n\n  <div class='padding-b-h'>\n    {{t 'marketplaceIntegrationDesc'}}\n  </div>\n</div>\n\n<div class='padding-b-2'>\n  <Marketplace::IntegrationList />\n</div>", {"contents":"<h2 class='black-text bold padding-t-3q padding-l-h'>\n  {{t 'marketplace'}}\n</h2>\n\n<div class='padding-t-2 padding-l-1'>\n  <h3 class='black-text bold'>\n    {{t 'plugins'}}\n  </h3>\n\n  <div class='padding-b-h'>\n    {{t 'marketplacePluginDesc'}}\n  </div>\n</div>\n\n<div class='padding-b-2'>\n  <Marketplace::PluginList />\n</div>\n\n<div class='padding-l-1'>\n  <h3 class='black-text bold'>\n    {{t 'integration'}}\n  </h3>\n\n  <div class='padding-b-h'>\n    {{t 'marketplaceIntegrationDesc'}}\n  </div>\n</div>\n\n<div class='padding-b-2'>\n  <Marketplace::IntegrationList />\n</div>","moduleName":"irene/components/marketplace/index.hbs","parseOptions":{"srcName":"irene/components/marketplace/index.hbs"}});
import Component from '@glimmer/component';

export default class MarketplaceComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Marketplace: typeof MarketplaceComponent;
  }
}
