import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <div class='box-container margin-top'>\n    <div class='columns'>\n      <div class='column is-half'>\n        <h5 class='bold black-text is-fullwidth'>\n          {{t 'changePassword'}}\n        </h5>\n\n        <AccountSettings::Security::PasswordChange />\n      </div>\n    </div>\n  </div>\n\n  <div class='padding-h-1 padding-t-1 padding-b-3'>\n    <div class='columns'>\n      <div class='column'>\n        <AccountSettings::Security::MultiFactorAuth @user={{@user}} />\n      </div>\n    </div>\n  </div>\n</div>", {"contents":"<div>\n  <div class='box-container margin-top'>\n    <div class='columns'>\n      <div class='column is-half'>\n        <h5 class='bold black-text is-fullwidth'>\n          {{t 'changePassword'}}\n        </h5>\n\n        <AccountSettings::Security::PasswordChange />\n      </div>\n    </div>\n  </div>\n\n  <div class='padding-h-1 padding-t-1 padding-b-3'>\n    <div class='columns'>\n      <div class='column'>\n        <AccountSettings::Security::MultiFactorAuth @user={{@user}} />\n      </div>\n    </div>\n  </div>\n</div>","moduleName":"irene/components/account-settings/security/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/security/index.hbs"}});
import Component from '@glimmer/component';
import UserModel from 'irene/models/user';

export interface AccountSettingsSecuritySignature {
  Args: {
    user: UserModel;
  };
}

export default class AccountSettingsSecurityComponent extends Component<AccountSettingsSecuritySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountSettings::Security': typeof AccountSettingsSecurityComponent;
  }
}
