import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='black-text bold font-medium'>\n  {{@title}}\n</div>\n\n<div local-class='marketplace-card-logo'>\n  <img src='{{@logo}}' alt='card-logo' />\n</div>\n\n<div local-class='marketplace-card-desc'>\n  {{@text}}\n</div>", {"contents":"<div class='black-text bold font-medium'>\n  {{@title}}\n</div>\n\n<div local-class='marketplace-card-logo'>\n  <img src='{{@logo}}' alt='card-logo' />\n</div>\n\n<div local-class='marketplace-card-desc'>\n  {{@text}}\n</div>","moduleName":"irene/components/marketplace/card-content/index.hbs","parseOptions":{"srcName":"irene/components/marketplace/card-content/index.hbs"}});
import Component from '@glimmer/component';

export interface MarketplaceCardContentSignature {
  Args: {
    title: string;
    logo: string;
    text: string;
  };
}

export default class MarketplaceCardContentComponent extends Component<MarketplaceCardContentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Marketplace::CardContent': typeof MarketplaceCardContentComponent;
    'marketplace/card-content': typeof MarketplaceCardContentComponent;
  }
}
