import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='box-container margin-top'>\n  <div class='columns'>\n    <div class='column is-one-third'>\n      <h5 class='bold black-text'>\n        {{t 'language'}}\n      </h5>\n\n      {{t 'selectLanguage'}}\n\n      <div class='language-select'>\n        <AccountSettings::General::SelectLanguage />\n      </div>\n    </div>\n  </div>\n</div>", {"contents":"<div class='box-container margin-top'>\n  <div class='columns'>\n    <div class='column is-one-third'>\n      <h5 class='bold black-text'>\n        {{t 'language'}}\n      </h5>\n\n      {{t 'selectLanguage'}}\n\n      <div class='language-select'>\n        <AccountSettings::General::SelectLanguage />\n      </div>\n    </div>\n  </div>\n</div>","moduleName":"irene/components/account-settings/general/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/general/index.hbs"}});
import Component from '@glimmer/component';

export default class AccountSettingsGeneralComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountSettings::General': typeof AccountSettingsGeneralComponent;
  }
}
