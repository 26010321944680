import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='control'>\n  <select\n    id='settings-select-language'\n    class='mp-select-language'\n    {{on 'change' (perform this.setLocale)}}\n    disabled={{this.setLocale.isRunning}}\n  >\n    {{#each this.allLocales as |al|}}\n      <option selected={{eq this.currentLocale al.locale}} value={{al.locale}}>\n        {{al.localeString}}\n      </option>\n    {{/each}}\n  </select>\n</div>", {"contents":"<div class='control'>\n  <select\n    id='settings-select-language'\n    class='mp-select-language'\n    {{on 'change' (perform this.setLocale)}}\n    disabled={{this.setLocale.isRunning}}\n  >\n    {{#each this.allLocales as |al|}}\n      <option selected={{eq this.currentLocale al.locale}} value={{al.locale}}>\n        {{al.localeString}}\n      </option>\n    {{/each}}\n  </select>\n</div>","moduleName":"irene/components/account-settings/general/select-language/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/general/select-language/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';

import ENV from 'irene/config/environment';
import DatetimeService from 'irene/services/datetime';

const localeStrings = {
  en: 'English',
  ja: '日本語',
};

type LocaleKeys = keyof typeof localeStrings;

export default class AccountSettingsGeneralSelectLanguageComponent extends Component {
  @service declare intl: IntlService;
  @service declare ajax: any;
  @service declare datetime: DatetimeService;
  @service('notifications') declare notify: NotificationService;

  get currentLocale() {
    return this.intl.locale.toString();
  }

  get allLocales() {
    return this.intl.locales
      .map((locale) => ({
        locale,
        localeString: localeStrings[locale as LocaleKeys],
      }))
      .filter((f) => Boolean(f.localeString));
  }

  setLocale = task(async (event: Event) => {
    const lang = (event.target as HTMLSelectElement).value;

    this.intl.locale = lang;

    this.datetime.setLocale(lang);

    const data = {
      lang,
    };

    try {
      await this.ajax.post(ENV.endpoints['lang'], { data });

      if (!this.isDestroyed) {
        window.location.reload();
      }
    } catch (err) {
      const error = err as AdapterError;
      this.notify.error(error.payload.message);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountSettings::General::SelectLanguage': typeof AccountSettingsGeneralSelectLanguageComponent;
  }
}
