import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<tr>\n  <td>{{@invoice.id}}</td>\n  <td>{{@invoice.planName}}</td>\n  <td>${{@invoice.amount}}</td>\n  <td>{{@invoice.paidDate}}</td>\n  <td>{{@invoice.paidStatus}}</td>\n\n  <td>\n    <AkButton {{on 'click' (perform this.getInvoiceLink)}} @variant='outlined'>\n      <:leftIcon>\n        {{#if this.getInvoiceLink.isRunning}}\n          <AkLoader @size={{13}} />\n        {{else}}\n          <AkIcon @iconName='download' />\n        {{/if}}\n      </:leftIcon>\n\n      <:default>{{t 'download'}}</:default>\n    </AkButton>\n  </td>\n</tr>", {"contents":"<tr>\n  <td>{{@invoice.id}}</td>\n  <td>{{@invoice.planName}}</td>\n  <td>${{@invoice.amount}}</td>\n  <td>{{@invoice.paidDate}}</td>\n  <td>{{@invoice.paidStatus}}</td>\n\n  <td>\n    <AkButton {{on 'click' (perform this.getInvoiceLink)}} @variant='outlined'>\n      <:leftIcon>\n        {{#if this.getInvoiceLink.isRunning}}\n          <AkLoader @size={{13}} />\n        {{else}}\n          <AkIcon @iconName='download' />\n        {{/if}}\n      </:leftIcon>\n\n      <:default>{{t 'download'}}</:default>\n    </AkButton>\n  </td>\n</tr>","moduleName":"irene/components/organization-billing/invoice-list/overview/index.hbs","parseOptions":{"srcName":"irene/components/organization-billing/invoice-list/overview/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import ENV from 'irene/config/environment';
import InvoiceModel from 'irene/models/invoice';
import { task } from 'ember-concurrency';

interface OrganizationBillingInvoiceListOverviewSignature {
  Args: {
    invoice: InvoiceModel;
  };
}

export default class OrganizationBillingInvoiceListOverviewComponent extends Component<OrganizationBillingInvoiceListOverviewSignature> {
  @service declare ajax: any;
  @service('browser/window') declare window: Window;
  @service('notifications') declare notify: NotificationService;

  getInvoiceLink = task({ drop: true }, async () => {
    const downloadUrl = this.args.invoice.downloadUrl;
    const url = new URL(downloadUrl, ENV.host).href;

    try {
      const result = await this.ajax.request(url);

      if (!this.isDestroyed) {
        this.window.location.href = result.url;
      }
    } catch (error) {
      this.notify.error('Sorry something went wrong, please try again');
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationBilling::InvoiceList::Overview': typeof OrganizationBillingInvoiceListOverviewComponent;
  }
}
