import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: AkSvg::AmTableEmpty}}\n<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  local-class='empty-container'\n  data-test-am-errorContainer\n>\n\n  <AkSvg::AmTableEmpty\n    local-class='empty-state-vector'\n    data-test-am-errorIllustration\n  />\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    data-test-am-emptyHeaderText\n  >\n    {{@header}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text' data-test-am-emptyBodyText>\n    {{@body}}\n  </AkTypography>\n</AkStack>", {"contents":"{{! @glint-nocheck: AkSvg::AmTableEmpty}}\n<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  local-class='empty-container'\n  data-test-am-errorContainer\n>\n\n  <AkSvg::AmTableEmpty\n    local-class='empty-state-vector'\n    data-test-am-errorIllustration\n  />\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    data-test-am-emptyHeaderText\n  >\n    {{@header}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text' data-test-am-emptyBodyText>\n    {{@body}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/app-monitoring/empty/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/empty/index.hbs"}});
import Component from '@glimmer/component';

interface AppMonitoringEmptySignature {
  Element: HTMLDivElement;
  Args: {
    header?: string;
    body?: string;
  };
}

export default class AppMonitoringEmptyComponent extends Component<AppMonitoringEmptySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppMonitoring::Empty': typeof AppMonitoringEmptyComponent;
  }
}
