import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='invoice-table'>\n  {{#if this.hasInvoices}}\n    <h4 class='margin-left20 bold black-text'>\n      {{t 'invoices'}}\n    </h4>\n\n    <div class='invoice-list-container'>\n      <table>\n        <thead>\n          <tr>\n            <th>{{t 'invoiceId'}}</th>\n            <th>{{t 'planName'}}</th>\n            <th>{{t 'amountPaid'}}</th>\n            <th>{{t 'date'}}</th>\n            <th class='uppercase-text'>{{t 'status'}}</th>\n            <th>{{t 'invoice'}}</th>\n          </tr>\n        </thead>\n\n        <tbody local-class='invoice-tbody'>\n          {{#each this.invoices as |invoice|}}\n            <OrganizationBilling::InvoiceList::Overview @invoice={{invoice}} />\n          {{/each}}\n        </tbody>\n      </table>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class='invoice-table'>\n  {{#if this.hasInvoices}}\n    <h4 class='margin-left20 bold black-text'>\n      {{t 'invoices'}}\n    </h4>\n\n    <div class='invoice-list-container'>\n      <table>\n        <thead>\n          <tr>\n            <th>{{t 'invoiceId'}}</th>\n            <th>{{t 'planName'}}</th>\n            <th>{{t 'amountPaid'}}</th>\n            <th>{{t 'date'}}</th>\n            <th class='uppercase-text'>{{t 'status'}}</th>\n            <th>{{t 'invoice'}}</th>\n          </tr>\n        </thead>\n\n        <tbody local-class='invoice-tbody'>\n          {{#each this.invoices as |invoice|}}\n            <OrganizationBilling::InvoiceList::Overview @invoice={{invoice}} />\n          {{/each}}\n        </tbody>\n      </table>\n    </div>\n  {{/if}}\n</div>","moduleName":"irene/components/organization-billing/invoice-list/index.hbs","parseOptions":{"srcName":"irene/components/organization-billing/invoice-list/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import InvoiceModel from 'irene/models/invoice';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

type InvoicesResponse = DS.AdapterPopulatedRecordArray<InvoiceModel>;

export default class OrganizationBillingInvoiceListComponent extends Component {
  @service declare store: Store;

  @tracked invoices: InvoicesResponse | null = null;

  constructor(owner: unknown, args: object) {
    super(owner, args);

    this.fetchInvoices.perform();
  }

  fetchInvoices = task(async () => {
    this.invoices = (await this.store.findAll('invoice')) as InvoicesResponse;
  });

  get hasInvoices() {
    return (this.invoices?.toArray() || []).length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationBilling::InvoiceList': typeof OrganizationBillingInvoiceListComponent;
  }
}
