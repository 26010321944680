export default {
  "analysis-details-root": "_analysis-details-root_1nv92z",
  "analysis-details-header": "_analysis-details-header_1nv92z",
  "analysis-section": "_analysis-section_1nv92z",
  "analysis-content": "_analysis-content_1nv92z",
  "analysis-static-content": "_analysis-static-content_1nv92z",
  "analysis-content-title": "_analysis-content-title_1nv92z",
  "analysis-content-value": "_analysis-content-value_1nv92z",
  "vulnerabiliy-finding-container": "_vulnerabiliy-finding-container_1nv92z",
  "vulnerabiliy-finding-description": "_vulnerabiliy-finding-description_1nv92z"
};
