export default {
  "bold": "_bold_1qb8fw",
  "semi-bold": "_semi-bold_1qb8fw",
  "font-medium": "_font-medium_1qb8fw",
  "font-legible": "_font-legible_1qb8fw",
  "font-regular": "_font-regular_1qb8fw",
  "font-small": "_font-small_1qb8fw",
  "text-lightgray": "_text-lightgray_1qb8fw",
  "text-darkgray": "_text-darkgray_1qb8fw",
  "text-success": "_text-success_1qb8fw",
  "text-danger": "_text-danger_1qb8fw",
  "table-lightgray": "_table-lightgray_1qb8fw",
  "btn-table": "_btn-table_1qb8fw",
  "td-with-btn": "_td-with-btn_1qb8fw",
  "table-compact": "_table-compact_1qb8fw",
  "empty-state": "_empty-state_1qb8fw",
  "fa": "_fa_1qb8fw",
  "fill-primary": "_fill-primary_1qb8fw",
  "fill-silver": "_fill-silver_1qb8fw",
  "fill-lightgray": "_fill-lightgray_1qb8fw",
  "fill-white": "_fill-white_1qb8fw",
  "btn": "_btn_1qb8fw",
  "bordered-box": "_bordered-box_1qb8fw",
  "alert": "_alert_1qb8fw",
  "alert-link": "_alert-link_1qb8fw",
  "alert-table": "_alert-table_1qb8fw",
  "fill-success-box": "_fill-success-box_1qb8fw",
  "badge-square": "_badge-square_1qb8fw",
  "is-default": "_is-default_1qb8fw",
  "is-sidebar": "_is-sidebar_1qb8fw",
  "#{$spacer}-t-#{nth($fp, 1)": "_#{$spacer}-t-#{nth($fp, 1)_1qb8fw",
  "#{$spacer}-v-#{nth($fp, 1)": "_#{$spacer}-v-#{nth($fp, 1)_1qb8fw",
  "#{$spacer}-b-#{nth($fp, 1)": "_#{$spacer}-b-#{nth($fp, 1)_1qb8fw",
  "#{$spacer}-l-#{nth($fp, 1)": "_#{$spacer}-l-#{nth($fp, 1)_1qb8fw",
  "#{$spacer}-h-#{nth($fp, 1)": "_#{$spacer}-h-#{nth($fp, 1)_1qb8fw",
  "#{$spacer}-r-#{nth($fp, 1)": "_#{$spacer}-r-#{nth($fp, 1)_1qb8fw",
  "#{$spacer}-t-#{$i}": "_#{$spacer}-t-#{$i}_1qb8fw",
  "#{$spacer}-v-#{$i}": "_#{$spacer}-v-#{$i}_1qb8fw",
  "#{$spacer}-b-#{$i}": "_#{$spacer}-b-#{$i}_1qb8fw",
  "#{$spacer}-l-#{$i}": "_#{$spacer}-l-#{$i}_1qb8fw",
  "#{$spacer}-h-#{$i}": "_#{$spacer}-h-#{$i}_1qb8fw",
  "#{$spacer}-r-#{$i}": "_#{$spacer}-r-#{$i}_1qb8fw",
  "#{$spacer}-t-#{$i}h": "_#{$spacer}-t-#{$i}h_1qb8fw",
  "#{$spacer}-v-#{$i}h": "_#{$spacer}-v-#{$i}h_1qb8fw",
  "#{$spacer}-b-#{$i}h": "_#{$spacer}-b-#{$i}h_1qb8fw",
  "#{$spacer}-l-#{$i}h": "_#{$spacer}-l-#{$i}h_1qb8fw",
  "#{$spacer}-h-#{$i}h": "_#{$spacer}-h-#{$i}h_1qb8fw",
  "#{$spacer}-r-#{$i}h": "_#{$spacer}-r-#{$i}h_1qb8fw",
  "margin-top30": "_margin-top30_1qb8fw",
  "margin-top-10": "_margin-top-10_1qb8fw",
  "margin-top-20": "_margin-top-20_1qb8fw",
  "margin-top-5": "_margin-top-5_1qb8fw",
  "margin-top50": "_margin-top50_1qb8fw",
  "margin-top": "_margin-top_1qb8fw",
  "margin-top5": "_margin-top5_1qb8fw",
  "margin-top3": "_margin-top3_1qb8fw",
  "margin-top20": "_margin-top20_1qb8fw",
  "margin-top35": "_margin-top35_1qb8fw",
  "margin-left": "_margin-left_1qb8fw",
  "margin-left5": "_margin-left5_1qb8fw",
  "margin-left30": "_margin-left30_1qb8fw",
  "margin-left0": "_margin-left0_1qb8fw",
  "margin-left20": "_margin-left20_1qb8fw",
  "margin-right": "_margin-right_1qb8fw",
  "margin-right20": "_margin-right20_1qb8fw",
  "margin-left-10": "_margin-left-10_1qb8fw",
  "margin-right-40": "_margin-right-40_1qb8fw",
  "margin-right100": "_margin-right100_1qb8fw",
  "margin-top-bottom": "_margin-top-bottom_1qb8fw",
  "margin-bottom0": "_margin-bottom0_1qb8fw",
  "margin-bottom": "_margin-bottom_1qb8fw",
  "margin-bottom20": "_margin-bottom20_1qb8fw",
  "margin-bottom-20": "_margin-bottom-20_1qb8fw",
  "margin-bottom40": "_margin-bottom40_1qb8fw",
  "margin-bottom50": "_margin-bottom50_1qb8fw",
  "margin-h-neg-20": "_margin-h-neg-20_1qb8fw",
  "margin-v-neg-20": "_margin-v-neg-20_1qb8fw",
  "margin-t-neg-20": "_margin-t-neg-20_1qb8fw",
  "margin-b-neg-20": "_margin-b-neg-20_1qb8fw",
  "padding-bottom0": "_padding-bottom0_1qb8fw",
  "top0": "_top0_1qb8fw",
  "maxwidth65": "_maxwidth65_1qb8fw",
  "margin-both": "_margin-both_1qb8fw",
  "multiple-files-manual": "_multiple-files-manual_1qb8fw",
  "multiple-files-no-manual": "_multiple-files-no-manual_1qb8fw",
  "no-multiple-files-manual": "_no-multiple-files-manual_1qb8fw",
  "no-multiple-files-no-manual": "_no-multiple-files-no-manual_1qb8fw",
  "reduce-padding": "_reduce-padding_1qb8fw",
  "margin-auto": "_margin-auto_1qb8fw",
  "flex-grow-1": "_flex-grow-1_1qb8fw",
  "flex-grow-2": "_flex-grow-2_1qb8fw",
  "margin-side-auto": "_margin-side-auto_1qb8fw",
  "width-100": "_width-100_1qb8fw",
  "display-inline": "_display-inline_1qb8fw",
  "display-inline-block": "_display-inline-block_1qb8fw",
  "contain-width": "_contain-width_1qb8fw",
  "appknox-logo": "_appknox-logo_1qb8fw",
  "font-size12": "_font-size12_1qb8fw",
  "button": "_button_1qb8fw",
  "no-border": "_no-border_1qb8fw",
  "is-icon": "_is-icon_1qb8fw",
  "width100": "_width100_1qb8fw",
  "black-text": "_black-text_1qb8fw",
  "red-text": "_red-text_1qb8fw",
  "default-text": "_default-text_1qb8fw",
  "upper-nav": "_upper-nav_1qb8fw",
  "scan-status": "_scan-status_1qb8fw",
  "fa-check": "_fa-check_1qb8fw",
  "fa-times": "_fa-times_1qb8fw",
  "float-right": "_float-right_1qb8fw",
  "current-user": "_current-user_1qb8fw",
  "logo-img": "_logo-img_1qb8fw",
  "edit-button": "_edit-button_1qb8fw",
  "edit-input": "_edit-input_1qb8fw",
  "textarea-input": "_textarea-input_1qb8fw",
  "other-devices": "_other-devices_1qb8fw",
  "title-tag": "_title-tag_1qb8fw",
  "login-phone-image": "_login-phone-image_1qb8fw",
  "box-section": "_box-section_1qb8fw",
  "plan-text": "_plan-text_1qb8fw",
  "choose-units": "_choose-units_1qb8fw",
  "column": "_column_1qb8fw",
  "plus-icon": "_plus-icon_1qb8fw",
  "minus-icon": "_minus-icon_1qb8fw",
  "box-sub-section": "_box-sub-section_1qb8fw",
  "box-section-content": "_box-section-content_1qb8fw",
  "pricing-section-content": "_pricing-section-content_1qb8fw",
  "invoice-overview": "_invoice-overview_1qb8fw",
  "horizontal-seperator": "_horizontal-seperator_1qb8fw",
  "is-reverse": "_is-reverse_1qb8fw",
  "status-box": "_status-box_1qb8fw",
  "file-input": "_file-input_1qb8fw",
  "input-file": "_input-file_1qb8fw",
  "or": "_or_1qb8fw",
  "not-found": "_not-found_1qb8fw",
  "implication-list": "_implication-list_1qb8fw",
  "scrollable-box": "_scrollable-box_1qb8fw",
  "pre-code": "_pre-code_1qb8fw",
  "user-info": "_user-info_1qb8fw",
  "user-box": "_user-box_1qb8fw",
  "text-centre": "_text-centre_1qb8fw",
  "text-right": "_text-right_1qb8fw",
  "vertical-align-center": "_vertical-align-center_1qb8fw",
  "vertical-align-top": "_vertical-align-top_1qb8fw",
  "vertical-align-stretch": "_vertical-align-stretch_1qb8fw",
  "flex-column": "_flex-column_1qb8fw",
  "align-middle": "_align-middle_1qb8fw",
  "align-start": "_align-start_1qb8fw",
  "align-end": "_align-end_1qb8fw",
  "align-both": "_align-both_1qb8fw",
  "align-both-centered": "_align-both-centered_1qb8fw",
  "align-center": "_align-center_1qb8fw",
  "strip": "_strip_1qb8fw",
  "recover-form": "_recover-form_1qb8fw",
  "account-overview": "_account-overview_1qb8fw",
  "account-overview-details": "_account-overview-details_1qb8fw",
  "modal-footer": "_modal-footer_1qb8fw",
  "delete": "_delete_1qb8fw",
  "form-spacing": "_form-spacing_1qb8fw",
  "is-progress": "_is-progress_1qb8fw",
  "padding-10": "_padding-10_1qb8fw",
  "capitalize-text": "_capitalize-text_1qb8fw",
  "uppercase-text": "_uppercase-text_1qb8fw",
  "lowercase-text": "_lowercase-text_1qb8fw",
  "copy-icon": "_copy-icon_1qb8fw",
  "risk-icons": "_risk-icons_1qb8fw",
  "fa-font-size-small": "_fa-font-size-small_1qb8fw",
  "fa-font-size": "_fa-font-size_1qb8fw",
  "columns-main-container": "_columns-main-container_1qb8fw",
  "is-two-third": "_is-two-third_1qb8fw",
  "box-container": "_box-container_1qb8fw",
  "box-container-w-b": "_box-container-w-b_1qb8fw",
  "divider": "_divider_1qb8fw",
  "project-container": "_project-container_1qb8fw",
  "project-loading-container": "_project-loading-container_1qb8fw",
  "top-nav": "_top-nav_1qb8fw",
  "scan-text": "_scan-text_1qb8fw",
  "project-count": "_project-count_1qb8fw",
  "top-icons": "_top-icons_1qb8fw",
  "sidebar-icons": "_sidebar-icons_1qb8fw",
  "side-menu": "_side-menu_1qb8fw",
  "box": "_box_1qb8fw",
  "card": "_card_1qb8fw",
  "menu": "_menu_1qb8fw",
  "container": "_container_1qb8fw",
  "menu-list": "_menu-list_1qb8fw",
  "menu-list-title-beta": "_menu-list-title-beta_1qb8fw",
  "menu-list-link": "_menu-list-link_1qb8fw",
  "active": "_active_1qb8fw",
  "menu-list-title-container": "_menu-list-title-container_1qb8fw",
  "menu-list-icons": "_menu-list-icons_1qb8fw",
  "bg-color-container": "_bg-color-container_1qb8fw",
  "theme-dark": "_theme-dark_1qb8fw",
  "theme-light": "_theme-light_1qb8fw",
  "menu-label": "_menu-label_1qb8fw",
  "auth-main-container": "_auth-main-container_1qb8fw",
  "has-text-centered": "_has-text-centered_1qb8fw",
  "scroll-down": "_scroll-down_1qb8fw",
  "input": "_input_1qb8fw",
  "card-form": "_card-form_1qb8fw",
  "copy-password": "_copy-password_1qb8fw",
  "api-scanning": "_api-scanning_1qb8fw",
  "fa-exclamation-triangle": "_fa-exclamation-triangle_1qb8fw",
  "exclamation-warning": "_exclamation-warning_1qb8fw",
  "no-data": "_no-data_1qb8fw",
  "warning-band": "_warning-band_1qb8fw",
  "fa-trash-o": "_fa-trash-o_1qb8fw",
  "position-icons": "_position-icons_1qb8fw",
  "edit-filter": "_edit-filter_1qb8fw",
  "modal-button": "_modal-button_1qb8fw",
  "control": "_control_1qb8fw",
  "start-scan": "_start-scan_1qb8fw",
  "overflow": "_overflow_1qb8fw",
  "ellipse": "_ellipse_1qb8fw",
  "hide-overflow": "_hide-overflow_1qb8fw",
  "package-name": "_package-name_1qb8fw",
  "wrap-text": "_wrap-text_1qb8fw",
  "app-icon": "_app-icon_1qb8fw",
  "overflow-hidden": "_overflow-hidden_1qb8fw",
  "hidden-overflow": "_hidden-overflow_1qb8fw",
  "fixed-icon": "_fixed-icon_1qb8fw",
  "error-page": "_error-page_1qb8fw",
  "package-desc": "_package-desc_1qb8fw",
  "api-scan-status": "_api-scan-status_1qb8fw",
  "personal-token-component": "_personal-token-component_1qb8fw",
  "token-table": "_token-table_1qb8fw",
  "namespace-component": "_namespace-component_1qb8fw",
  "input-column": "_input-column_1qb8fw",
  "api-filter-table": "_api-filter-table_1qb8fw",
  "edit-form-input": "_edit-form-input_1qb8fw",
  "device-table": "_device-table_1qb8fw",
  "report-preferences": "_report-preferences_1qb8fw",
  "question": "_question_1qb8fw",
  "invoice-table": "_invoice-table_1qb8fw",
  "invoice-list-container": "_invoice-list-container_1qb8fw",
  "card-container": "_card-container_1qb8fw",
  "card-details": "_card-details_1qb8fw",
  "card-form-header": "_card-form-header_1qb8fw",
  "columns": "_columns_1qb8fw",
  "last-child": "_last-child_1qb8fw",
  "card-header": "_card-header_1qb8fw",
  "pricing-title": "_pricing-title_1qb8fw",
  "auto-adjust": "_auto-adjust_1qb8fw",
  "hideOrShow": "_hideOrShow_1qb8fw",
  "os-icon": "_os-icon_1qb8fw",
  "card-icon": "_card-icon_1qb8fw",
  "file-card": "_file-card_1qb8fw",
  "card-content": "_card-content_1qb8fw",
  "content": "_content_1qb8fw",
  "app-information": "_app-information_1qb8fw",
  "compare-container": "_compare-container_1qb8fw",
  "team-overview-card": "_team-overview-card_1qb8fw",
  "project-specific": "_project-specific_1qb8fw",
  "card-button": "_card-button_1qb8fw",
  "file-hide-overflow": "_file-hide-overflow_1qb8fw",
  "more-details": "_more-details_1qb8fw",
  "file-buttons": "_file-buttons_1qb8fw",
  "file-icon": "_file-icon_1qb8fw",
  "user-roles-table": "_user-roles-table_1qb8fw",
  "tag": "_tag_1qb8fw",
  "table-head": "_table-head_1qb8fw",
  "manual-scan-modal": "_manual-scan-modal_1qb8fw",
  "modal-sub-container": "_modal-sub-container_1qb8fw",
  "modal-card": "_modal-card_1qb8fw",
  "text-area": "_text-area_1qb8fw",
  "no-border-column": "_no-border-column_1qb8fw",
  "user-roles": "_user-roles_1qb8fw",
  "card-footer-item": "_card-footer-item_1qb8fw",
  "card-header-icon": "_card-header-icon_1qb8fw",
  "condition-text-small": "_condition-text-small_1qb8fw",
  "warning-modal": "_warning-modal_1qb8fw",
  "modal-small": "_modal-small_1qb8fw",
  "modal-medium": "_modal-medium_1qb8fw",
  "condition-text": "_condition-text_1qb8fw",
  "show": "_show_1qb8fw",
  "edit": "_edit_1qb8fw",
  "save": "_save_1qb8fw",
  "visible": "_visible_1qb8fw",
  "invisible": "_invisible_1qb8fw",
  "hide": "_hide_1qb8fw",
  "details": "_details_1qb8fw",
  "progress-wrap": "_progress-wrap_1qb8fw",
  "progress": "_progress_1qb8fw",
  "is-success": "_is-success_1qb8fw",
  "chartjs-render-monitor": "_chartjs-render-monitor_1qb8fw",
  "message-body": "_message-body_1qb8fw",
  "icon-effect": "_icon-effect_1qb8fw",
  "message-header": "_message-header_1qb8fw",
  "severity-tags": "_severity-tags_1qb8fw",
  "message": "_message_1qb8fw",
  "is-untested": "_is-untested_1qb8fw",
  "is-errored": "_is-errored_1qb8fw",
  "vuln-header": "_vuln-header_1qb8fw",
  "seperator": "_seperator_1qb8fw",
  "mp-minus": "_mp-minus_1qb8fw",
  "message-meta-data": "_message-meta-data_1qb8fw",
  "finished-scan": "_finished-scan_1qb8fw",
  "unfinished-scan": "_unfinished-scan_1qb8fw",
  "scan-tag": "_scan-tag_1qb8fw",
  "vuln-table": "_vuln-table_1qb8fw",
  "vulnerability-types": "_vulnerability-types_1qb8fw",
  "scan-table": "_scan-table_1qb8fw",
  "risk-table": "_risk-table_1qb8fw",
  "risk-values": "_risk-values_1qb8fw",
  "button-width": "_button-width_1qb8fw",
  "app-details-card": "_app-details-card_1qb8fw",
  "scan-status-card": "_scan-status-card_1qb8fw",
  "info-band": "_info-band_1qb8fw",
  "mfa": "_mfa_1qb8fw",
  "qr-code": "_qr-code_1qb8fw",
  "otp-apps": "_otp-apps_1qb8fw",
  "fa-upload": "_fa-upload_1qb8fw",
  "font-size-18": "_font-size-18_1qb8fw",
  "font-size-16": "_font-size-16_1qb8fw",
  "font-size-14": "_font-size-14_1qb8fw",
  "font-size-25": "_font-size-25_1qb8fw",
  "fa-download": "_fa-download_1qb8fw",
  "fa-cog": "_fa-cog_1qb8fw",
  "fa-exchange": "_fa-exchange_1qb8fw",
  "fa-navicon": "_fa-navicon_1qb8fw",
  "cvss-box": "_cvss-box_1qb8fw",
  "team-container": "_team-container_1qb8fw",
  "table-container": "_table-container_1qb8fw",
  "finding-list-table": "_finding-list-table_1qb8fw",
  "attachments-list-table": "_attachments-list-table_1qb8fw",
  "security-list-table": "_security-list-table_1qb8fw",
  "button-small": "_button-small_1qb8fw",
  "file-action-container": "_file-action-container_1qb8fw",
  "attachment": "_attachment_1qb8fw",
  "edit-analysis-box": "_edit-analysis-box_1qb8fw",
  "marked-analysis": "_marked-analysis_1qb8fw",
  "edit-marked-analysis": "_edit-marked-analysis_1qb8fw",
  "org-tab-content-container": "_org-tab-content-container_1qb8fw",
  "org": "_org_1qb8fw",
  "tabs": "_tabs_1qb8fw",
  "is-boxed": "_is-boxed_1qb8fw",
  "is-active": "_is-active_1qb8fw",
  "no-hover-button": "_no-hover-button_1qb8fw",
  "success": "_success_1qb8fw",
  "critical": "_critical_1qb8fw",
  "remove": "_remove_1qb8fw",
  "input-checkbox": "_input-checkbox_1qb8fw",
  "copy-token": "_copy-token_1qb8fw",
  "table-h-bordered-gray": "_table-h-bordered-gray_1qb8fw",
  "table-bordered-gray": "_table-bordered-gray_1qb8fw",
  "pending-invitation-table": "_pending-invitation-table_1qb8fw",
  "team-card": "_team-card_1qb8fw",
  "team-header": "_team-header_1qb8fw",
  "filter-list": "_filter-list_1qb8fw",
  "api-filter": "_api-filter_1qb8fw",
  "new-input-box": "_new-input-box_1qb8fw",
  "delete-button": "_delete-button_1qb8fw",
  "bi-span": "_bi-span_1qb8fw",
  "bullet-list": "_bullet-list_1qb8fw",
  "code-block": "_code-block_1qb8fw",
  "delete-tag": "_delete-tag_1qb8fw",
  "is-waiting": "_is-waiting_1qb8fw",
  "edit-tag": "_edit-tag_1qb8fw",
  "threshold-tag": "_threshold-tag_1qb8fw",
  "colored-box": "_colored-box_1qb8fw",
  "is-#{$name}": "_is-#{$name}_1qb8fw",
  "is-error": "_is-error_1qb8fw",
  "owasp-tag": "_owasp-tag_1qb8fw",
  "vuln-body": "_vuln-body_1qb8fw",
  "risk-type-tag": "_risk-type-tag_1qb8fw",
  "scan-category": "_scan-category_1qb8fw",
  "owasp-category": "_owasp-category_1qb8fw",
  "feedback-button": "_feedback-button_1qb8fw",
  "pending-invitation": "_pending-invitation_1qb8fw",
  "box-title": "_box-title_1qb8fw",
  "invitation-list": "_invitation-list_1qb8fw",
  "no-project-container": "_no-project-container_1qb8fw",
  "no-project": "_no-project_1qb8fw",
  "repeat-icon": "_repeat-icon_1qb8fw",
  "device-modal": "_device-modal_1qb8fw",
  "modal-card-head": "_modal-card-head_1qb8fw",
  "vnc-modal-body": "_vnc-modal-body_1qb8fw",
  "count": "_count_1qb8fw",
  "loading-container": "_loading-container_1qb8fw",
  "login-wrapper": "_login-wrapper_1qb8fw",
  "vnc-viewer": "_vnc-viewer_1qb8fw",
  "marvel-device": "_marvel-device_1qb8fw",
  "screen": "_screen_1qb8fw",
  "vnc-box": "_vnc-box_1qb8fw",
  "top-bar": "_top-bar_1qb8fw",
  "modal": "_modal_1qb8fw",
  "ipad": "_ipad_1qb8fw",
  "tablet": "_tablet_1qb8fw",
  "canvas": "_canvas_1qb8fw",
  "vnc-text": "_vnc-text_1qb8fw",
  "iphone5s": "_iphone5s_1qb8fw",
  "home": "_home_1qb8fw",
  "nexus5": "_nexus5_1qb8fw",
  "ac-upload-app": "_ac-upload-app_1qb8fw",
  "url-filters": "_url-filters_1qb8fw",
  "add-filter-list": "_add-filter-list_1qb8fw",
  "js-duration-button": "_js-duration-button_1qb8fw",
  "plan-durations": "_plan-durations_1qb8fw",
  "plan-list": "_plan-list_1qb8fw",
  "payment-info-page": "_payment-info-page_1qb8fw",
  "language-select": "_language-select_1qb8fw",
  "input-modal": "_input-modal_1qb8fw",
  "input-width": "_input-width_1qb8fw",
  "prompt-modal": "_prompt-modal_1qb8fw",
  "deleted-item": "_deleted-item_1qb8fw",
  "modal-card-title": "_modal-card-title_1qb8fw",
  "subscription-container": "_subscription-container_1qb8fw",
  "td-heading": "_td-heading_1qb8fw",
  "scan-section": "_scan-section_1qb8fw",
  "all-inputs": "_all-inputs_1qb8fw",
  "upload-app": "_upload-app_1qb8fw",
  "success-button": "_success-button_1qb8fw",
  "highlighted-button": "_highlighted-button_1qb8fw",
  "risk-status-box": "_risk-status-box_1qb8fw",
  "stat-heading": "_stat-heading_1qb8fw",
  "user-info-popover": "_user-info-popover_1qb8fw",
  "user-name": "_user-name_1qb8fw",
  "user-email": "_user-email_1qb8fw",
  "status-container": "_status-container_1qb8fw",
  "status-list": "_status-list_1qb8fw",
  "no-reason": "_no-reason_1qb8fw",
  "status-details": "_status-details_1qb8fw",
  "is-critical": "_is-critical_1qb8fw",
  "is-danger": "_is-danger_1qb8fw",
  "is-warning": "_is-warning_1qb8fw",
  "is-info": "_is-info_1qb8fw",
  "status-reason": "_status-reason_1qb8fw",
  "click-here": "_click-here_1qb8fw",
  "new-users": "_new-users_1qb8fw",
  "welcome-text": "_welcome-text_1qb8fw",
  "welcome-desc": "_welcome-desc_1qb8fw",
  "welcome-sub-desc": "_welcome-sub-desc_1qb8fw",
  "welcome-footer-text": "_welcome-footer-text_1qb8fw",
  "chart-container": "_chart-container_1qb8fw",
  "stat-home-page": "_stat-home-page_1qb8fw",
  "scan-list": "_scan-list_1qb8fw",
  "main-box-container": "_main-box-container_1qb8fw",
  "box-content": "_box-content_1qb8fw",
  "scrolling-box": "_scrolling-box_1qb8fw",
  "box-lists": "_box-lists_1qb8fw",
  "box-list-items": "_box-list-items_1qb8fw",
  "chart": "_chart_1qb8fw",
  "chart-2": "_chart-2_1qb8fw",
  "box-main-heading": "_box-main-heading_1qb8fw",
  "app-scanned": "_app-scanned_1qb8fw",
  "home-page": "_home-page_1qb8fw",
  "stat-table-container": "_stat-table-container_1qb8fw",
  "scan-counts": "_scan-counts_1qb8fw",
  "check-circle": "_check-circle_1qb8fw",
  "check-circle-check": "_check-circle-check_1qb8fw",
  "check-circle-text": "_check-circle-text_1qb8fw",
  "knob": "_knob_1qb8fw",
  "toggle-switch": "_toggle-switch_1qb8fw",
  "toggle-switch-small": "_toggle-switch-small_1qb8fw",
  "toggle-switch-on-off": "_toggle-switch-on-off_1qb8fw",
  "toggle-switch-small-on-off": "_toggle-switch-small-on-off_1qb8fw",
  "switch": "_switch_1qb8fw",
  "slider": "_slider_1qb8fw",
  "yes-no-slider": "_yes-no-slider_1qb8fw",
  "slider-nolabel": "_slider-nolabel_1qb8fw",
  "round": "_round_1qb8fw",
  "stat-container": "_stat-container_1qb8fw",
  "switch-input": "_switch-input_1qb8fw",
  "not-found-container": "_not-found-container_1qb8fw",
  "not-found-section": "_not-found-section_1qb8fw",
  "login-form": "_login-form_1qb8fw",
  "sso-button": "_sso-button_1qb8fw",
  "top-columns": "_top-columns_1qb8fw",
  "login-button": "_login-button_1qb8fw",
  "analysis-header": "_analysis-header_1qb8fw",
  "sort-icons": "_sort-icons_1qb8fw",
  "fa-sort-up": "_fa-sort-up_1qb8fw",
  "fa-sort-down": "_fa-sort-down_1qb8fw",
  "my-popper": "_my-popper_1qb8fw",
  "popper--white": "_popper--white_1qb8fw",
  "regulatary-section": "_regulatary-section_1qb8fw",
  "section": "_section_1qb8fw",
  "signup-container": "_signup-container_1qb8fw",
  "edit-analysis": "_edit-analysis_1qb8fw",
  "edit-analysis-modal": "_edit-analysis-modal_1qb8fw",
  "marked-analysis-text": "_marked-analysis-text_1qb8fw",
  "marked-analysis-text2": "_marked-analysis-text2_1qb8fw",
  "mark-text": "_mark-text_1qb8fw",
  "mark-analysis-all": "_mark-analysis-all_1qb8fw",
  "modal-card-foot": "_modal-card-foot_1qb8fw",
  "signup-form": "_signup-form_1qb8fw",
  "register-form": "_register-form_1qb8fw",
  "register-confirm": "_register-confirm_1qb8fw",
  "intl-tel-input": "_intl-tel-input_1qb8fw",
  "g-recaptcha": "_g-recaptcha_1qb8fw",
  "checkbox-field": "_checkbox-field_1qb8fw",
  "user-edited-icon": "_user-edited-icon_1qb8fw",
  "vulnerability-container": "_vulnerability-container_1qb8fw",
  "vuln-warning-band": "_vuln-warning-band_1qb8fw",
  "select-box": "_select-box_1qb8fw",
  "vuln-band": "_vuln-band_1qb8fw",
  "risk-comment": "_risk-comment_1qb8fw",
  "device-modal-body": "_device-modal-body_1qb8fw",
  "dynamic-scan-on-vnc": "_dynamic-scan-on-vnc_1qb8fw",
  "dynamic-scan-note": "_dynamic-scan-note_1qb8fw",
  "dynamic-scan-buttons": "_dynamic-scan-buttons_1qb8fw",
  "vnc-button": "_vnc-button_1qb8fw",
  "vnc-button-repeat": "_vnc-button-repeat_1qb8fw",
  "vnc-completed-button": "_vnc-completed-button_1qb8fw",
  "notification-position": "_notification-position_1qb8fw",
  "_c-notification__content_of6y9q": "__c-notification__content_of6y9q_1qb8fw",
  "_c-notification__icon_of6y9q": "__c-notification__icon_of6y9q_1qb8fw",
  "fa-info-circle": "_fa-info-circle_1qb8fw",
  "copy-password-modal": "_copy-password-modal_1qb8fw",
  "compare-details": "_compare-details_1qb8fw",
  "file-id-container": "_file-id-container_1qb8fw",
  "file-id-text": "_file-id-text_1qb8fw",
  "file-in-active-icon": "_file-in-active-icon_1qb8fw",
  "app-risk-info": "_app-risk-info_1qb8fw",
  "compare-text": "_compare-text_1qb8fw",
  "arrow-left": "_arrow-left_1qb8fw",
  "device-preferences": "_device-preferences_1qb8fw",
  "request-status": "_request-status_1qb8fw",
  "back-link": "_back-link_1qb8fw",
  "list-heading": "_list-heading_1qb8fw",
  "border-bottom-container": "_border-bottom-container_1qb8fw",
  "analysis-box": "_analysis-box_1qb8fw",
  "cvss-score-section": "_cvss-score-section_1qb8fw",
  "checkbox": "_checkbox_1qb8fw",
  "security-link": "_security-link_1qb8fw",
  "security-dashboard": "_security-dashboard_1qb8fw",
  "paginate": "_paginate_1qb8fw",
  "all-scan-status": "_all-scan-status_1qb8fw",
  "flex-center": "_flex-center_1qb8fw",
  "user": "_user_1qb8fw",
  "show-button": "_show-button_1qb8fw",
  "fa-user-circle": "_fa-user-circle_1qb8fw",
  "teamcard": "_teamcard_1qb8fw",
  "add-team-objects-scroll": "_add-team-objects-scroll_1qb8fw",
  "team-project": "_team-project_1qb8fw",
  "platform-icon": "_platform-icon_1qb8fw",
  "vuln-modal": "_vuln-modal_1qb8fw",
  "modal-l": "_modal-l_1qb8fw",
  "network-state": "_network-state_1qb8fw",
  "green": "_green_1qb8fw",
  "collaboration-settings": "_collaboration-settings_1qb8fw",
  "dropdown": "_dropdown_1qb8fw",
  "dropdown--default": "_dropdown--default_1qb8fw",
  "date-input-class": "_date-input-class_1qb8fw",
  "no-issues": "_no-issues_1qb8fw",
  "flex-2": "_flex-2_1qb8fw",
  "flex-3": "_flex-3_1qb8fw",
  "warning-msg": "_warning-msg_1qb8fw",
  "code-link": "_code-link_1qb8fw",
  "w-inherit": "_w-inherit_1qb8fw",
  "power-select-custom-threshold": "_power-select-custom-threshold_1qb8fw",
  "power-select-custom-repo": "_power-select-custom-repo_1qb8fw",
  "power-select-custom-width": "_power-select-custom-width_1qb8fw",
  "font-size-unset": "_font-size-unset_1qb8fw",
  "integration": "_integration_1qb8fw",
  "bottom-change": "_bottom-change_1qb8fw",
  "archive-row": "_archive-row_1qb8fw",
  "license-detail": "_license-detail_1qb8fw",
  "full-height": "_full-height_1qb8fw",
  "automation-script-upload": "_automation-script-upload_1qb8fw",
  "file-upload": "_file-upload_1qb8fw",
  "primary-color": "_primary-color_1qb8fw"
};
